.h-fit-content {
    height: fit-content !important;
}

.min-h-fit-content {
    min-height: fit-content !important;
}

.max-h-fit-content {
    max-height: fit-content !important;
}

.w-fit-content {
    width: fit-content !important;
}

.min-w-fit-content {
    min-width: fit-content !important;
}

.max-w-fit-content {
    max-width: fit-content !important;
}

.h-unset {
    height: unset !important;
}

.vh-25 {
    height: 25vh;
}

.vh-50 {
    height: 50vh;
}

.vh-55 {
    height: 55vh;
}

.vh-60 {
    height: 60vh;
}

.vh-75 {
    height: 75vh;
}

.w-unset {
    width: unset !important;
}

.w-35 {
    width: 35% !important;
}

.w-65 {
    width: 65% !important;
}

.min-h-80 {
    height: 80% !important;
}

.min-h-90 {
    height: 90% !important;
}

.max-w-100px {
    max-width: 100px !important;
}

.max-w-100 {
    max-width: 100% !important;
}

.max-w-75 {
    max-width: 75% !important;
}

.max-w-50 {
    max-width: 50% !important;
}

.max-w-25 {
    max-width: 25% !important;
}

.left-minus-5 {
    left: -5% !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-3 {
    padding-left: 0.75rem !important;
}

.pl-6 {
    padding-left: 1.5rem !important;
}

.pl-10 {
    padding-left: 2.5rem !important;
}

.pl-11 {
    padding-left: 2.75rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pr-3 {
    padding-right: 0.75rem !important;
}

.pr-10 {
    padding-right: 2.5rem !important;
}

.pr-11 {
    padding-right: 2.75rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-3 {
    margin-left: 0.75rem !important;
}

.ml-5 {
    margin-left: 1.25rem !important;
}

.ml-6 {
    margin-left: 1.5rem !important;
}

.ml-10 {
    margin-left: 2.5rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-3 {
    margin-right: 0.75rem !important;
}

.mr-5 {
    margin-right: 1.25rem !important;
}

.mr-6 {
    margin-right: 1.5rem !important;
}

.mr-10 {
    margin-right: 2.5rem !important;
}

.h-16px {
    height: 16px !important;
}

.w-16px {
    width: 16px !important;
}

/* Custom Text */

.text-black-25 {
    color: rgba(0, 0, 0, 0.25) !important;
}

.text-black-75 {
    color: rgba(0, 0, 0, 0.75) !important;
}

.text-success-dark {
    color: #1B806A !important;
}

.text-warning-main {
    color: #FFAB00 !important;
}

.text-warning-dark {
    color: #B76E00 !important;
}

.text-error-dark {
    color: #FF5630 !important;
}

.text-teal {
    color: #006C9C !important;
}

.text-purple {
    color: #a700ed !important;
}

.text-brown {
    color: #964B00 !important;
}

.text-darkcyan {
    color: #008B8B !important;
}

.info-text {
    color: rgba(99, 115, 129, 1);
}

/* Custom Backgrounds */


.bg-grey-300 {
    background-color: #DFE3E8 !important;
}

.bg-secondary-light {
    background-color: #919EAB29 !important;
}

.bg-secondary-extra-light {
    background-color: #F4F6F8 !important;
}

.bg-warning-light {
    background-color: #FFAB0029 !important;
}

.bg-success-light {
    background-color: #36B37E29 !important;
}

.bg-error {
    background-color: #FF5630 !important;
}

.bg-error-light {
    background-color: rgba(255, 86, 48, 0.16) !important;
}

.bg-warning-main {
    background-color: #FFAB00 !important;
}

.bg-warning-main-light {
    background-color: rgba(255, 171, 0, 0.16) !important;
}

.bg-teal {
    background-color: #00B8D9 !important;
}

.bg-purple {
    background-color: rgba(167, 0, 237, 1) !important;
}

.bg-light-purple {
    background-color: rgba(167, 0, 237, 0.16) !important;
}

.bg-brown {
    background-color: rgba(150, 75, 0, 1) !important;
}

.bg-light-brown {
    background-color: rgba(150, 75, 0, 0.16) !important;
}

.bg-darkcyan {
    background-color: rgba(0, 139, 139, 1) !important;
}

.bg-light-darkcyan {
    background-color: rgba(0, 139, 139, 0.16) !important;
}

/* Table */

.custom-table {
    align-items: flex-start;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    position: relative;
    width: 100%;

    .content {
        align-items: center;
        background-color: #f4f6f8;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        padding: 8px 5px;
        position: relative;
        width: 100%;
    }

    table {
        border-collapse:separate;
        border-spacing: 0 0.5rem;

        thead {
            background-color: #f4f6f8 !important;
            border: none !important;
        }

        th {
            background-color: #f4f6f8 !important;
            border: none !important;
        }

        .table-headers-row {
            .th-sorting-icon-container {
                .th-text {
                    cursor: pointer !important;
                    color: rgba(99, 115, 129, 1);
                }

                .th-sorting-icon {
                    height: 24px !important;
                    width: 24px !important;
                    background-image: url("/assets/common/icons/arrows-up-down.svg") !important;
                    background-size: 15px !important;
                    background-repeat: no-repeat !important;
                    background-position: center;
                    border-radius: 50%;
                }

                .th-sorting-icon.asc {
                    height: 24px !important;
                    width: 24px !important;
                    background-image: url("/assets/common/icons/arrows-transfer-down.svg") !important;
                    background-size: 15px !important;
                    background-repeat: no-repeat !important;
                    background-position: center;
                    border-radius: 50%;
                }

                .th-sorting-icon.desc {
                    height: 24px !important;
                    width: 24px !important;
                    background-image: url("/assets/common/icons/arrows-transfer-up.svg") !important;
                    background-size: 15px !important;
                    background-repeat: no-repeat !important;
                    background-position: center;
                    border-radius: 50%;
                }
            }

            .th-sorting-icon-container:hover {
                cursor: pointer !important;

                .th-sorting-icon {
                    background-color: rgba(145, 158, 171, 0.24) !important;
                    cursor: pointer !important;
                }
            }

            .th-sorting-icon-container.disabled {
                cursor: default !important;

                .th-text {
                    cursor: default !important;
                    color: rgba(99, 115, 129, 1);
                }

                .th-sorting-icon {
                    cursor: default !important;
                    opacity: 0.5 !important;
                    pointer-events: none !important;
                }
            }

            .th-sorting-icon-container.disabled:hover {
                .th-sorting-icon {
                    background-color: inherit !important;
                }
            }
        }

        .label-container {
            align-items: center;
            display: flex;
            margin-left: -2px;
            padding: 3px 8px;
            position: relative;
            max-width: 120px;
            height: 100%;

            .owner-text {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        .owner-container {
            background-color: #919EAB29;
            cursor: pointer;

            .owner-icon-container {
                border-radius: 50%;
                height: 30px;
                width: 30px;
            }
        }

        .shared-icon-container:hover {
            background-color: rgba(51, 102, 255, 0.08) !important;
            cursor: pointer !important;
        }

        .info-icon-container.yellow:hover {
            background-color: rgba(255, 171, 0, 0.08) !important;
            cursor: pointer !important;
        }

        .info-icon-container.red:hover {
            background-color: rgba(176, 0, 32, 0.08) !important;
            cursor: pointer !important;
        }

        .info-icon-container.grey:hover {
            background-color: rgba(145, 158, 171, 0.08) !important;
            cursor: pointer !important;
        }

        .more-icon-container:hover {
            background-color: rgba(145, 158, 171, 0.08) !important;
            border-radius: 25px !important;
            cursor: pointer !important;
        }
    }
}

/* Buttons */

.new-button {
    color: white !important;
    background-color: #a700ed !important;
}

.cancel-button {
    border: 1px solid #919EAB52 !important;
    background-color: #fff !important;
    color: #212B36 !important;
}

.cancel-button:hover {
    background-color: rgba(145, 158, 171, 0.08) !important
}

.clear-button {
    background-color: rgba(255, 86, 48, 0.161) !important;
}

.clear-button:hover {
    background-color: rgba(255, 86, 48, 0.25) !important;
}

.proceed-button {
    background-color: #a700ed !important;
    color: white !important;
}

.proceed-button:disabled {
    background-color: rgba(145, 158, 171, 0.24) !important;
    color: rgba(145, 158, 171, 0.8) !important;
}

.proceed-button:hover {
    background-color: rgba(167, 0, 237, 0.9) !important;
}

.proceed-button-outline {
    border: 1px solid rgba(167, 0, 237, 0.48) !important;
    background-color: white !important;
    color: #a700ed !important;
}

.proceed-button-outline:hover {
    background-color: rgba(167, 0, 237, 0.08) !important;
}

.teal-button-outline {
    border: 1px solid rgba(0, 184, 217, 0.48) !important;
    background-color: white !important;
    color: #00B8D9 !important;
}

.teal-button-outline:hover {
    background-color: rgba(0, 184, 217, 0.08) !important;
}

.save-button {
    background-color: #a700ed !important;
    color: white !important;

    .save-button-icon {
        height: 18px !important;
        width: 18px !important;
        background-image: url('/assets/common/icons/save-white.svg') !important;
        background-size: 18px !important;
        background-repeat: no-repeat !important;
    }
}

.save-button:disabled {
    background-color: rgba(145, 158, 171, 0.24) !important;
    color: rgba(145, 158, 171, 0.8) !important;

    .save-button-icon {
        height: 18px !important;
        width: 18px !important;
        background-image: url('/assets/common/icons/save-dark-disabled.svg') !important;
        background-size: 18px !important;
        background-repeat: no-repeat !important;
    }
}

/* Form */

.labeled-text-input-group {
    .text-input-label {
        background-color: #FFFFFF;
        position: relative;
        top: 30%;
        left: 5%;
        height: 2px;
        z-index: 1;

        span {
            color: #919EAB;
            position: relative;
            top: -10px;
            z-index: 1;

            .label-info-icon {
                height: 12px;
                width: 12px;
            }

            .label-calculator-icon {
                height: 12px;
                width: 12px;
            }
        }
    }

    .text-input-label.invalid {
        .label-info-icon {
            content: url("/assets/common/icons/info-red.svg");
        }

        .label-calculator-icon {
            content: url("/assets/common/icons/calculator-red.svg");
        }
    }

    .text-input {
        background-color: #FFFFFF;
        border: 1px solid #919EAB52;
        color: #637381;
    }
}

.labeled-select-group {
    .select-label {
        background-color: #FFFFFF;
        position: relative;
        top: 30%;
        left: 5%;
        height: 2px;
        z-index: 1;

        span {
            color: #919EAB;
            position: relative;
            top: -10px;
            z-index: 1;

            .label-info-icon {
                height: 12px;
                width: 12px;
            }
        }
    }

    .select-label.invalid {
        .label-info-icon {
            content: url("/assets/common/icons/info-red.svg");
        }
    }

    .select-input {
        background-color: #FFFFFF;
        border: 1px solid #919EAB52;
        color: #637381;
    }
}

.labeled-date-input-group {
    .date-input-label {
        background-color: #FFFFFF;
        position: relative;
        top: 30%;
        left: 5%;
        height: 2px;
        z-index: 1;

        span {
            color: #919EAB;
            position: relative;
            top: -10px;
            z-index: 1;

            .label-info-icon {
                height: 12px;
                width: 12px;
            }
        }
    }

    .date-input-label.invalid {
        .label-info-icon {
            content: url("/assets/common/icons/info-red.svg");
        }
    }

    .date-input {
        background-color: #FFFFFF;
        border: 1px solid #919EAB52;
        color: #637381;
    }
}

.form-control[readonly] {
    background-color: #eff2f5 !important;
}

.custom-moment-time-picker-container {
    input {
        height: 24px !important;
    }

    .btn-link {
        max-height: 24px !important;

        .bs-chevron-down {
            top: -8px !important;
        }
    }
}

.custom-moment-time-picker-container.invalid {
    input {
        border: 1px solid rgba(241, 65, 108) !important;
    }

    .btn-link {
        border: 1px solid rgba(241, 65, 108) !important;
    }

    .bs-chevron {
        color: rgba(241, 65, 108) !important;
    }
}

.radio-input-purple {
    border: 1.5px solid #637381 !important;
    background-color: #FFFFFF !important;

    transition-duration: .2s;
    transition-delay: .1s;
}

.radio-input-purple:checked {
    border: 1.5px solid #a700ed !important;
    background-color: #FFFFFF !important;
    background-image: url("/assets/common/icons/radio-button-image-purple.svg") !important;
}

.form-check-input-purple:checked {
    background-color: #a700ed !important;
    border: 1px solid #a700ed !important;
}

.p-checkbox-box:not(.p-highlight) {
    background-color: #FFFFFF !important;
    border: 2px solid #757575 !important;
}

.p-checkbox-box.p-highlight {
    background-color: #a700ed !important;
    border: 2px solid #a700ed !important;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 8px !important;
    left: 2px !important;
}

.p-checkbox-box.p-indeterminate {
    background-color: #890C587A !important;
    border: none !important;

    .p-checkbox-icon {
        color: #FFFFFF !important;
    }
}

.p-treenode-content:hover {
    background: rgba(167, 0, 237, 0.08) !important;
}

/* Custom Inputs */

.search-text-input {
    align-items: center;
    align-self: stretch;
    border: 1px solid #919eab52;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    overflow: hidden;
    position: relative;
    background-image: url(/assets/common/icons/magnifying-glass.svg);
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: 5px;
    padding: 8px 14px 8px 40px;
    outline: none;
    width: 100%;
}

/* Validation */

.form-group {
    label.invalid {
        span {
            color: rgba(241, 65, 108) !important;

            transition-duration: .1s;
            transition-delay: .1s;
        }
    }

    .ng-touched.ng-invalid {
        border: 1px solid rgba(241, 65, 108);

        transition-duration: .1s;
        transition-delay: .1s;
    }

    .ng-dirty.ng-invalid {
        border: 1px solid rgba(241, 65, 108);

        transition-duration: .1s;
        transition-delay: .1s;
    }
}

.form-group {
    height: 65px !important;
}

/* Dropdown */

.dropdown-menu {
    .hovering-li {
        cursor: pointer !important;
    }

    .hovering-li:hover {
        border-radius: 10px !important;
        background: #f4f5f8 !important;
        text-decoration: none !important;
    }
}

/* Custom Dropdown */

.custom-dropdown {
    position: absolute;
    z-index: 2;

    transition-duration: .3s;

    .custom-dropdown-menu {
        overflow: auto;
    }
}

/* Custom Select */

.custom-select {
    .custom-select-menu {
        overflow: auto;

        position: absolute;
        z-index: 2;

        transition-duration: .3s;
    }
}

/* Custom Icons */

.grey-icon-container:hover {
    background-color: rgba(145, 158, 171, 0.08) !important;
    border-radius: 25px !important;
    cursor: pointer !important;
}

.clear-search-icon {
    height: 30px !important;
    width: 30px !important;
    padding-left: .5rem !important;

    transition-property: height, width, padding-left;
    transition-duration: .5s;
    transition-delay: 0.1s;
}

.clear-search-icon.hidden {
    height: 0 !important;
    width: 0 !important;
    padding-left: 0 !important;
}
